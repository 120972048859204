/* Timeline Section */
.timeline-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  background-color: white;
  box-sizing: border-box; /* Ensure padding is included in width calculation */
}

/* Timeline Container */
.timeline-container {
  position: relative;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensure it fits within the container */
}

/* Individual Timeline Item */
.timeline-item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 30px 0;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

/* Vertical Line */
.timeline-line {
  position: absolute;
  left: 50px;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #ccc;
  z-index: 1; /* Ensure it stays behind other elements */
}

/* Timeline Icon (Circle) */
.timeline-icon {
  width: 150px;
  height: 150px;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  flex-shrink: 0; /* Prevent shrinking of the icon */
  margin: 0 auto; /* Center the icon */
}

.timeline-icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Make the image round */
  object-fit: cover; /* Ensure the image covers the entire circle */
}

/* Timeline Content (Text) */
.timeline-content {
  margin-left: 100px;
  max-width: 600px;
  flex: 1; /* Allow the content to grow */
}

.timeline-content h3 {
  font-size: 24px;
  color: #007bff;
  font-weight: bold;
}

.timeline-content p {
  font-size: 20px;
  color: #333;
  line-height: 1.5;
}

.timeline-section .cta-button {
  background-color: #1C74B6;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 4px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}
.timeline-section .cta-button .cta-link {
  color: inherit; /* Inherit color from the button */
  text-decoration: none !important; 
  text-decoration-style: none !important; 
  display: block; /* Ensure the link takes up the full button space */
  width: 100%;
 
}
.timeline-section .cta-button:hover {
  background-color: #1C74B6;
}
 
/* Responsive Styles */
@media (max-width: 768px) {
 
  .timeline-container {
    padding-left: 20px;
  }

  .timeline-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0;
  }

  .timeline-line {
    left: 40px;  /* Adjust position for smaller screens  */
    height: auto; /* Allow the line to adjust dynamically */
    top: 0;
    bottom: 0;
    display: none; /* Hide the vertical line for smaller screens */
  }

  .timeline-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 20px; /* Add spacing below the icon */
  }

  .timeline-content {
    margin-left: 0;
    max-width: 100%;
    text-align: left; /* Align text to the left */
  }

  .timeline-content h3 {
    font-size: 20px;
    margin-left: 11px;
  }

  .timeline-content p {
    font-size: 18px;
    margin-left: 11px;
  }
}

@media (max-width: 480px) {
  .timeline-section {
    padding: 20px 10px;
  }

  .timeline-container {
    padding-left: 10px;
  }

  .timeline-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 0;
  }

  .timeline-line {
    left: 10px; /* Adjust position for very small screens */
    height: auto; /* Allow the line to adjust dynamically */
    top: 0;
    bottom: 0;
  }

  .timeline-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 15px; /* Add spacing below the icon */
  }

  .timeline-content {
    margin-left: 0;
    max-width: 100%;
    text-align: left; /* Align text to the left */
  }

  .timeline-content h3 {
    font-size: 18px;
  }

  .timeline-content p {
    font-size: 16px;
  }

  .timeline-section .cta-button {
    font-size: 16px;
    padding: 10px 20px;
  }
}