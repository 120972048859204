/* Center the section */
.partners-section {
  max-width: 1300px;
  width: 90%;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}

/* Title Styling */
.partners-section .partners-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-left: 10px;
  text-align: left;
  color: #626262;

}

/* Center Slick slider */
.partners-section .slick-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

}

/* Partner logo container */
.partners-section .partner-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px  !important;
}

/* Partner Logos */
.partners-section .partner-logo {
  width: 90%;
  height: 250px;
  object-fit: contain;
}

/* Custom Arrows */
.partners-section .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 32px;
  color: #007bff; /* Blue color */
  cursor: pointer;
  z-index: 2;
}

.partners-section .slick-prev {
  left: -40px; /* Adjust as needed */
  font-weight: 600;
}

.partners-section .slick-next {
  right: -40px; /* Adjust as needed */
  font-weight: 600;
}

 
.partners-section .slick-arrow:hover {
  color: #0056b3; /* Darker blue on hover */
}

/* Fix Dots Position */
.partners-section .slick-dots {
  bottom: -15px;
}

.partners-section .slick-dots li button:before {
  font-size: 10px;
  color: #bbb;
}

.partners-section .slick-dots li.slick-active button:before {
  color: #007bff;
}


@media (max-width: 1024px) {
  .partners-section {
    width: 80%;
    margin: 0 auto;
    padding: 20px 10px;
  }
  .partners-section .partners-title {
    font-size: 1.8rem;
  }

  .partners-section .partner-logo {
    height: 200px;
  }

  .partners-section .slick-prev {
    left: -50px;
  }

  .partners-section .slick-next {
    right: -30px;
  }
}

@media (max-width: 768px) {
  .partners-section {
    width: 400px;
  }
  .partners-section .partners-title {
    font-size: 1.6rem;
    text-align: center;
  }

  .partners-section .partner-logo {
    height: 150px;
  }

  .partners-section .slick-prev {
    left: -40px;
  }

  .partners-section .slick-next {
    right: -20px;
  }
}

@media (max-width: 480px) {
  .partners-section {
    max-width: 90%;
  }

  
  .partners-section .partners-title {
    font-size: 1.4rem;
    text-align: center;
  }

  .partners-section .partner-logo {
    height: 100px;
  }

  .partners-section .slick-prev {
    left: -10px;
  }

  .partners-section .slick-next {
    right: 10px;
  }
}
 