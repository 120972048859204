/* News Section */
.news-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background-color: #f9f9f9;
  width: 100%;
 }

/* Container for all cards */
.news-section .news-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 4 columns */
  gap: 40px;
  max-width: 1200px;
  width: 100%;
  padding: 10px;
  margin: 0 10%;
}

/* Individual News Card */
.news-section .news-container .news-card {
  width: 100%;
  min-height: 500px;
  background-color: #808080;
  color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.news-section .news-container .news-card:hover {
  transform: translateY(-5px);
}

/* News Image */
.news-section .news-container .news-card .news-image {
  width: 100%;
  height: 45%;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
}

/* Category Badge */
.news-section .news-container .news-card .news-category {
  background-color: #2172c1;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 15px;
  text-transform: uppercase;
}

/* News Content */
.news-section .news-container .news-card .news-content {
  background-color: #808080;
  color: white;
  padding: 20px;
 
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news-section .news-container .news-card .news-content h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Read More Link */
.news-section .news-container .news-card .news-content .news-link {
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.news-section .news-container .news-card .news-content .news-link:hover {
  color: #ddd;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .news-section .news-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    justify-content: center;
  }

  .news-section .news-container .news-card {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .news-section .news-container {
    display: grid;
    grid-template-columns: 1fr; /* Ensures only 1 column */
    gap: 20px;
 
    justify-items: center; /* Centers the grid items */
  }

  .news-section .news-container .news-card {
    max-width: 90%; /* Adjust width for better fit */
    width: 100%; /* Ensures it takes full width */
    height: auto;
  }
}

@media (max-width: 480px) {
  .news-section .news-container .news-card {
    min-height: 400px;
  }

  .news-section .news-container .news-card .news-content h3 {
    font-size: 14px;
  }

  .news-section .news-container .news-card .news-content .news-link {
    font-size: 12px;
  }
}