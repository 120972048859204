/* Contact Section */
.contact-section {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  /* Background Image */
  .contact-image {
    width: 100%;
    height: auto;
    max-height: 400px; /* Adjust height as needed */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; /* Prevent image repetition */
    object-fit: cover; /* Ensure the image scales properly */
  }
  
  /* Contact Title Block */
  .contact-title {
    background-color: #666;
    color: white;
    text-align: justify;
    padding: 10px 300px;
    font-size: 24px;
    font-weight: bold;
    white-space: nowrap;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  }
  
  /* Responsive Styles */
@media (max-width: 768px) {
  .contact-image {
    height: 300px; /* Adjust height for tablets */
    background-repeat: no-repeat; /* Prevent image repetition */
    object-fit: cover; /* Ensure the image scales properly */
  }

  .contact-title {
    padding: 10px 20px; /* Adjust padding for tablets */
    font-size: 20px;
    text-align: center; /* Center text for tablets */
    white-space:unset;
  }
}

@media (max-width: 480px) {
  .contact-image {
    height: 200px; /* Adjust height for smartphones */
    background-repeat: no-repeat; /* Prevent image repetition */
    object-fit: cover; /* Ensure the image scales properly */
  }

  .contact-title {
    padding: 10px 20px; /* Adjust padding for smartphones */
    font-size: 18px;
    text-align: center; /* Center text for smartphones */
  }
}