/* Footer Styles */
.footer {
  background-color: #0c62aa; /* Blue background */
  color: white;
  padding: 40px 20px;
  width: 100%;
  max-width: 100%;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

/* Footer Content Layout */
.footer .footer-container {
  display: flex; 
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1300px;
  margin: 0 auto;
  gap: 30px; /* Add spacing between sections */
}

/* Logo Section */
.footer .footer-logo {
  flex: 1 1 10%;
  text-align: left;
}

.footer .footer-logo img {
  width: 150px;
  margin-bottom: 10px;
}

.footer .footer-logo p {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
}

/* Footer Sections */
.footer .footer-section {
  flex: 1 1 0px;
 
}

.footer .footer-section h3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer .footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-section ul li {
  margin-bottom: 12px;
}

.footer .footer-section ul li a {
  color: white;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease-in-out;
}

.footer .footer-section ul li a:hover {
  text-decoration: underline;
  color: #008d2a; /* Yellow on hover */
}

/* Social Media Icons */
.footer .footer-socials {
  display: flex;
  gap: 15px;
}

.footer .footer-socials a {
  color: white;
  font-size: 24px;
  transition: color 0.3s ease-in-out;
}

.footer .footer-socials a:hover {
  color: #008d2a; /* Yellow on hover */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer .footer-logo {
    text-align: center;
  }

  .footer .footer-section {
    margin-bottom: 0px;
  }

  .footer .footer-socials {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 20px 10px;
  }

  .footer .footer-logo img {
    width: 120px;
  }

  .footer .footer-logo p {
    font-size: 12px;
  }

  .footer .footer-section h3 {
    font-size: 14px;
  }

  .footer .footer-section ul li a {
    font-size: 12px;
  }

  .footer .footer-socials a {
    font-size: 20px;
  }
}