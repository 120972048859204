/* Popup Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Darker background for better contrast */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px); /* Add a blur effect to the background */
  }
  
  /* Popup Content */
  .popup-content {
    background: white;
    padding: 30px; /* Increase padding for more space */
    border-radius: 15px; /* Increase border radius for a softer look */
    max-width: 600px; /* Increase max-width for larger screens */
    text-align: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
    animation: fadeIn 0.5s ease-in-out; /* Add a fade-in animation */
  }
  
  /* Fade-in Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .popup-content h2 {
    font-size: 26px; /* Increase font size */
    margin-bottom: 20px; /* Increase margin for better spacing */
    color: #333; /* Darker color for better readability */
  }
  
  .popup-content p {
    font-size: 18px; /* Increase font size */
    margin-bottom: 20px; /* Increase margin for better spacing */
    color: #555; /* Darker color for better readability */
    line-height: 1.6; /* Increase line height for better readability */
  }
  
  .popup-buttons {
    display: flex;
    justify-content: space-around;
    gap: 20px; /* Add gap between buttons */
  }
  
  .popup-buttons button {
    background-color: #2172c1;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 25px; /* Increase padding for larger buttons */
    border: none;
    border-radius: 30px; /* Increase border radius for a softer look */
    cursor: pointer;
    transition: background 0.3s ease-in-out, transform 0.3s ease-in-out; /* Add transition for background and transform */
  }
  
  .popup-buttons button:hover {
    background-color: #195a8e;
    transform: translateY(-3px); /* Add a slight lift effect on hover */
  }