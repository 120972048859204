.job-post {
    max-width: 900px;
    margin: auto;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
  }
  
  .job-post .back-link {
    color: #3b82f6;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
  }
  
  .job-post .job-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .job-post .job-info {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    font-size: 14px;
    margin-bottom: 20px;
    color: #555;
  }
  
  .job-post .apply-button {
    background-color: #3b82f6;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .job-post .job-content {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .job-post .job-content .job-description {
    width: 65%;
  }
  
  .job-post .job-content .job-description p a {
    color: black;
    font-weight: bold;
    text-decoration: none;
  }
  
  
  .job-post  ul {
    padding-left: 20px;
  }
  
  .job-post .footer {
    text-align: center;
    margin-top: 20px;
    padding: 10px;
    background: #f8f9fa;
    font-size: 14px;
  }
  



  /* Responsive Styles */
@media (max-width: 1024px) {
  .job-post .job-content {
    flex-direction: column;
  }

  .job-post .job-content .job-description {
    width: 100%;
  }

  .job-post .job-info {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .job-post {
    padding: 0 20px;
  }

  .job-post .job-title {
    font-size: 20px;
  }

  .job-post .apply-button {
    width: 100%;
    text-align: center;
  }

  .job-post .job-content {
    flex-direction: column;
  }

  .job-post .job-content .job-description {
    width: 100%;
  }

  .job-post .job-info {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .job-post {
    padding: 0 10px;
  }

  .job-post .job-title {
    font-size: 18px;
  }

  .job-post .apply-button {
    width: 100%;
    text-align: center;
  }

  .job-post .job-content {
    flex-direction: column;
  }

  .job-post .job-content .job-description {
    width: 100%;
  }

  .job-post .job-info {
    flex-direction: column;
  }
}