/* Section Styling */
.production-section {
    width: 100%;
    position: relative;
    padding: 50px 0 0 0 ;
    text-align: center;
  }
  
  /* Section Title */
  .section-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  /* Two-column Layout */
  .production-row {
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    height: 500px;
    margin: 0 auto 30px auto;
    background-color: white;
    
  }
  
  /* Image Container */
  .production-image {
    flex: 1;
    max-width: 50%;
  }
  
  .production-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Content Container */
  .production-content {
    flex: 1;
    max-width: 50%;
    padding: 40px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    background-color: #0c62aa;
  }
  
  /* Headings */
  .production-content h3 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .production-content h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .production-content p {
    font-size: 20px;
    line-height: 1.5;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .production-row {
      flex-direction: column;
    }
  
    .production-image, .production-content {
      max-width: 100%;
    }
  
    .production-content {
      padding: 30px;
    }
  }
  