/* Section Styling */
.values-section {
  width: 100%;
  padding: 20px 0;
  text-align: center;
}

.values-section .section-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}

/* Values Section */
.values-section .values-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 50px;
}

.values-section .values-container .value-item {
  width: 240px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 80px;
}

.values-section .values-container .value-item img {
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Make the image round */
  object-fit: cover; /* Ensure the image covers the entire circle */
}

.values-section .values-container .light-blue {
  background-color: #C4D8F3; /* Light blue */
}

.values-section .values-container .dark-blue {
  background-color: #345BA8; /* Dark blue */
}
 
@media (max-width: 1024px) {
  .values-section .values-container {
    flex-wrap: wrap; /* Allow items to wrap */
    justify-content: center; /* Center the items */
    gap: 20px; /* Add spacing between items */
  }

  .values-section .values-container .value-item {
    width: 150px; /* Adjust size for medium screens */
    height: 150px; /* Adjust size for medium screens */
    margin-bottom: 10px;
  }
}
/* Responsive Styles */
@media (max-width: 768px) {
  .values-section .values-container {
    flex-direction: row;
    gap: 0px;
    
  }
  .values-container .value-item {
    min-width: 100px;
    min-height: 60px;
 

  }
  .values-section .commitments-container {
    grid-template-columns: 1fr;
  }

  .values-section .commitments-container .commitment-column {
    text-align: center;
  }

  .values-section .commitments-container .commitment-column ul {
    text-align: left;
  }
}

@media (max-width: 480px) {
  .values-section .section-title {
    font-size: 24px;
  }

  .values-section .values-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center; 
    justify-content: center;  
  }

  .values-section .values-container .value-item {
    width: 180px;
    height: 180px;
    margin: 0 40px;
  }

  .values-section .commitments-container {
    gap: 20px;
    padding: 0 10px;
  }

  .values-section .commitments-container .commitment-title {
    font-size: 16px;
  }

  .values-section .commitments-container .commitment-column p {
    font-size: 14px;
  }

  .values-section .commitments-container .commitment-column li {
    font-size: 14px;
  }
}