/* Section Styling */
.certifications {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 50px 0;
    background-color: white;
  }
  
  /* Content Container */
  .certifications-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    width: 90%;
  }
  
  /* Left Side - Text */
  .certifications-text {
    max-width: 60%;
    text-align: left;
    color: #333;
  }
  
  .certifications-text h2 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .certifications-text p {
    font-size: 20px;

    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  /* Blue Text */
  .blue-text {
    color: #276acb;
    font-weight: bold;
  }
  
  /* Right Side - Certification Logos */
  .certifications-logos {
    display: flex;
    gap: 20px;
  }
  
  .certification-logo {
    height: 100px;
    width: auto;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .certifications-container {
      flex-direction: column;
      text-align: center;
    }
  
    .certifications-text {
      max-width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
  
    .certifications-logos {
      justify-content: center;
    }
  }
  