.services {
  text-align: center;
  padding: 50px;
  background-color: #fff;
}

.services h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.services p {
  font-size: 22px;
  color: #666;
  max-width: 1100px;
  margin: 0 auto 30px;
  text-align: left;
}

.services .highlight {
  font-weight: bold;
  color: #2c68b4;
}

.services .services-grid {
  display: flex;
  grid-template-columns: repeat(4, 1fr); /* Create four equal-width columns */
  gap: 30px; /* Add some space between the cards */
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.services-grid .service-card {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.services-grid .service-card:hover {
  transform: translateY(-5px);
}

.services-grid .service-card .icon-circle {
  background: #ddd;
  border-radius: 50%;
  width: 200px; /* Adjusted width */
  height: 200px; /* Adjusted height */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 35px;
}

.services-grid .service-card .service-icon {
  width: 100%; /* Adjusted width */
  height: 100%; /* Adjusted height */
  border-radius: 50%; /* Make the image round */
  object-fit: cover; /* Ensure the image covers the entire circle */
}


.services-grid .service-card h3 {
  font-size: 20px;
  color: #2c68b4;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis (...) for overflow text */
}

.services-grid .service-card p {
  font-size: 20px;
  color: #555;
  text-align: left;
}

.services-grid .service-card a {
  display: block;
  margin-top: 50px;
  color: #2c68b4;
  font-weight: bold;
  text-decoration: none;
}

 


@media (max-width: 1024px) {
  .services .services-grid {
    display: grid; /* Use grid layout */
    grid-template-columns: repeat(2, 1fr); /* Two columns for medium screens */
    gap: 20px; /* Adjust spacing between cards */
    width: 100%; /* Ensure it fits within the window */
    margin: 0 auto; /* Center the grid */
    box-sizing: border-box; /* Include padding and border in width calculation */
    max-width: 100%; 
  }

  .services .services-grid .service-card {
    max-width: 100%; /* Ensure cards take full width of their container */
  }
}

@media (max-width: 768px) {
  .services .services-grid {
    display: block;
 
  }
 
  .services p {
    text-align: center;
  }

  .services .services-grid .service-card {
    margin-bottom: 30px;

 
  }
}

@media (max-width: 480px) {
  .services {
    padding: 0px;
    max-width: 100%;
  }

  .services h2 {
    font-size: 20px;
  }

  .services p {
    font-size: 16px;
    color: #666;
 
    margin: 0;
    padding: 0 10% 0 4%;
    text-align: left;
  }
 

  .services-grid .service-card h3 {
    font-size: 18px;
  }

  .services-grid .service-card p {
    font-size: 16px;
  }

  .services-grid .service-card a {
    font-size: 16px;
    margin-top: 20px;
  }

  .services-grid .service-card .icon-circle {
    width: 150px; /* Adjusted width for smaller screens */
    height: 150px; /* Adjusted height for smaller screens */
  }
}




