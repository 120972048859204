/* Section Container */
.gray-block {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  background-color: #808080; 
}

/* Background Image */
.gray-block .background-image {
  width: 100%;
  height: 450px;
  /* The background-image property is now set in the React component */
}

/* Content Block */
.gray-block .content-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Push text left & buttons right */
/* Dark grey background */
  color: white;
 
  box-sizing: border-box; /* Ensure padding doesn't affect layout */
  width: 100%; /* Ensure full width */
  max-width: 1200px; /* Optional: limit the maximum width */
}

/* Ensure full width for flex container */
.gray-block .content-container > * {
  flex: 1;
}

/* Text Content */
.gray-block .content-container .text-content {
  text-align: left;
}

/* Headings */
.gray-block .content-container .text-content h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Paragraphs */
.gray-block .content-container .text-content p {
  font-size: 22px;
  line-height: 1.6;
  font-weight: bold;
  margin-bottom: 15px;

}

/* Button Group */
/* .gray-block .content-container .button-group {
  display: flex;
  padding-left: 10%;
}

 
.gray-block .content-container .button-group .cta-button {
  background-color: white;
  color: black;
  padding: 25px 40px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  transition: 0.3s ease-in-out;
  font-size: 16px;
  min-width: 200px;
  display: inline-block;
}

 
.gray-block .content-container .button-group .cta-button.secondary {
  background-color: white;
  border: 2px solid white;
  color: #666;
}

.gray-block .content-container .button-group .cta-button:hover {
  background-color: #ddd;
  color: black;
} */

/* Responsive Styles */
@media (max-width: 1024px) {
  .gray-block .content-container {
    flex-direction: column;
    padding: 30px;
    text-align: center;
  }

  .gray-block .content-container .text-content {
    max-width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .gray-block .content-container .button-group {
    justify-content: center;
    margin-left: 0; /* Remove margin on smaller screens */
  }
}