/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 10px;
  position: sticky;
  top: 0;
  width: 100%; /* Ensure it takes the full width */
  max-width: 100%; /* Prevent it from exceeding the available width */
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px); /* Frosted glass effect */
  z-index: 10; /* Ensure it's above other elements */
  box-sizing: border-box; /* Include padding in width calculation */
}

.header .logo img {
  max-width: 100%;
  height: 70px;
  position: relative;
}

.header .nav {
  width: 100%; /* Ensure the navigation fits within the header */
  max-width: 1300px; /* Optional: Limit the maximum width */
  margin: 0 auto; /* Center the navigation */
  display: flex;
  justify-content: flex-end; /* Align menu items to the right */
}

.header .nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.header .nav ul li {
  position: relative;
}

.header .nav ul li a,
.header .nav ul li span {
  text-decoration: none;
  font-size: 18px;
  /* Change the font size */
  font-family: 'Arial', sans-serif;
  font-weight: bolder;
  color: black;
  font-weight: 900;
  padding: 10px;
  transition: color 0.3s ease-in-out;
  display: inline-block;
  vertical-align: middle;
  /* Ensure it aligns with other items */
}

.header .nav ul li a.active {
  color: #008d2a;
  border-bottom: 2px solid #008d2a;
}

.header .nav ul li a:hover,
.header .nav ul li span:hover {
  color: #008d2a;
  border-bottom: 4px solid #008d2a;
  transition: color 0.1s ease-in-out, border-bottom 0.2s ease-in-out;
}

/* Ensure the dropdown appears only on hover over "Nos domaines" */
.header .nav .dropdown {
  position: relative;
}

.header .nav .dropdown .dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  /* Ensure it appears below the "Nos domaines" */
  background-color: white;
  min-width: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  padding: 10px 0;
}

.header .nav .dropdown .dropdown-content li {
  padding: 12px 16px;
}

.header .nav .dropdown .dropdown-content li a {
  color: black;
  text-decoration: none;
  display: block;
  font-size: 16px;
}

.header .nav .dropdown .dropdown-content li a:hover {
  background-color: #f1f1f1;
}

/* Ensure dropdown only appears when hovering over "Nos domaines" */
.header .nav .dropdown:hover .dropdown-content {
  display: block;
}

/* Prevent the dropdown from pushing other elements */
.header .nav .dropdown>span {
  display: inline-block;
  padding: 10px;
  vertical-align: middle;
  /* Ensure it aligns with other items */
}

.header .nav .dropdown .dropdown-content.show {
  display: block;
}
 
/* Responsive Styles */
@media (max-width: 1061px) {
  .header {
    flex-direction: column;
    align-items: center;
    padding: 1px;
  }

  .header .nav {
    width: 100%; /* Ensure the navigation fits within the header */
  }

  /* Hamburger Button */
  .hamburger {
    display: block;
    cursor: pointer;
    font-size: 24px;
    background: none;
    border: none;
    color: black;
    padding: 10px;
  }

  /* Hide Menu by Default */
  .header .nav ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
  }

  /* Show Menu When Active */
  .header .nav ul.active {
    display: flex;
  }

  .header .nav ul li {
    width: 100%;
    padding: 10px 0;
  }

  .header .nav ul li a {
    display: block;
    width: 100%;
  }

  /* Mobile Dropdown */
  .header .nav .dropdown .dropdown-content {
    position: relative;
    display: none;
    background-color: white;
    text-align: center;
    box-shadow: none;
    padding: 0;
    width: 100%;
  }

  .header .nav .dropdown .dropdown-content.show {
    display: block;
  }

  .header .nav .dropdown .dropdown-content li {
    padding: 10px 0;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 20px 10px;
  }

  .header .nav ul {
    gap: 5px;
  }
}

@media (min-width: 1061px) {
  .hamburger {
    display: none;
  }
}

@media (max-width: 624px) {
  .header {
    max-width: 100%;
  }

}