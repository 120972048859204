/* Blog Section Container */
.blog-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
 
    background-color: #828282;
  }
  
  /* Image Row */
  .blog-section .blog-images {
    display: grid; /* Use grid layout for better responsiveness */
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust columns based on available space */
    gap: 20px; /* Add spacing between images */
    background-color: #2172c1; /* Blue background */
    padding: 30px 20px;
    width: 100%;
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  /* Circular Image */
  .blog-section .blog-images .blog-image-circle {
    width: 100%; /* Ensure the circle fits within its grid cell */
    max-width: 200px; /* Limit the maximum size */
    height: auto; /* Maintain aspect ratio */
    aspect-ratio: 1 / 1; /* Ensure the circle remains proportional */
    border-radius: 50%;
    overflow: hidden;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto; /* Center the circle within its grid cell */
  }
  
  .blog-section .blog-images  .blog-image-circle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Blog Content */
  .blog-section .blog-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    text-align: left;
    width: 70%;
    padding: 20px;
  }
  .blog-section .blog-content .blog-content-text {
    flex: 1;
    max-width: 60%;
  }
  .blog-section   .blog-content h2 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .blog-section  .blog-content p {
    font-size: 20px;

    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  /* Contact Button */
  .blog-content .blog-button {
    display: inline-block;
    background-color: #2172c1;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 25px;
    border-radius: 30px;
    text-decoration: none;
    transition: 0.3s ease-in-out;
    margin-right: 20%;
  }
  
  .blog-content .blog-button:hover {
    background-color: #195a8e;
  }
  
  @media (max-width: 1024px) {
    .blog-section .blog-content {
      flex-direction: column;
      text-align: center;
      width: 90%;
      padding: 30px 10px;
    }
  
    .blog-section .blog-content .blog-content-text {
      max-width: 100%;
      margin-bottom: 20px;
    }
  
    .blog-content .blog-button {
      margin-right: 0;
    }
    
  }
  @media (max-width: 768px) {
 
    .blog-section .blog-images {
      gap: 15px; /* Reduce spacing for smaller screens */
      width: 100%;
    }
  
    .blog-section .blog-images .blog-image-circle {
      max-width: 150px; /* Adjust size for smaller screens */
    }
  

  }
  
  @media (max-width: 480px) {
    .blog-section .blog-images {
      gap: 10px; /* Further reduce spacing for very small screens */
    }

    .blog-section .blog-images .blog-image-circle {
      max-width: 100px; /* Adjust size for very small screens */
    }
  
    .blog-section .blog-content h2 {
      font-size: 24px;
    }
  
    .blog-section .blog-content p {
      font-size: 16px;
    }
  
    .blog-content .blog-button {
      font-size: 14px;
      padding: 10px 20px;
    }
  }