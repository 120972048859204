/* Section Styling */
.company-about {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0;
  background-color: white;
}

/* Content Container */
.company-about .content-container {
  max-width: 1200px;
  width: 70%;
  text-align: left;
  color: #333;
 
}

/* Title */
.company-about .content-container .title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Paragraph Styling */
.company-about .content-container p {
  font-size: 20px;
  margin-bottom: 15px; /* Add some space between paragraphs */
}

/* Bold text */
.company-about .content-container strong {
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .company-about .content-container {
    width: 90%;
    text-align: center;
  }

  .company-about .content-container .title {
    font-size: 24px;
  }

  .company-about .content-container p {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .company-about {
    padding: 30px 0;
  }

  .company-about .content-container {
    width: 100%;
    padding: 0 10px;
  }

  .company-about .content-container .title {
    font-size: 20px;
  }

  .company-about .content-container p {
    font-size: 16px;
  }
}