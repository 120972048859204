/* Contact Section Container */
.contact-form-container {
  display: flex;
  justify-content: center; /* Center the component horizontally */
  align-items: flex-start;
  padding: 40px 20px; /* Reduce padding */
  gap: 50px;
  margin: 0 auto; /* Center the container */
  max-width: 1300px; /* Optional: Limit the maximum width */
}

.contact-form-container .contact-form-content {
  flex: 1;
  max-width: 50%;
}

.contact-form-container .contact-form-content h2 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}

.contact-form-container .contact-form-content .highlight {
  color: #0674eb;
  font-weight: bold;
}

.contact-form-container .contact-form-content p {
  font-size: 20px;
  color: #444;
  margin-bottom: 20px;
}

/* Contact Form */
.contact-form-container .contact-form-content .contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form-container .contact-form-content .contact-form input,
.contact-form-container .contact-form-content .contact-form textarea {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  border-radius: 30px;
  background-color: #7e7e7e;
  color: white;
  border: none;
  outline: none;
}
.contact-form-container .contact-form-content .contact-form input::placeholder,
.contact-form-container .contact-form-content .contact-form textarea::placeholder {
  color: #ffffff;  
  font-size: 16px;
}
.contact-form-container .contact-form-content .contact-form textarea {
  height: 100px;
  resize: none;
  border-radius: 20px;
}

.contact-form-container .contact-form-content .contact-form button {
  background-color: #0674eb;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 25px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  width: 150px;
  align-self: center;
}

.contact-form-container .contact-form-content .contact-form button:hover {
  background-color:#1182fb;
}

/* Disclaimer */
.contact-form-container .contact-form-content .disclaimer {
  font-size: 12px;
  color: #666;
  margin-top: 10px;
}

/* Right Side: Location & Image */
.contact-form-container .contact-location {
  flex: 1;
  max-width: 40%;
}

.contact-form-container .contact-location h3 {
  font-size: 18px;
  color: #0056b3;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-form-container .contact-location p {
  font-size: 20px;
  color: #444;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.contact-form-container .contact-location .text-center{
  padding-left: 100px;
}
/* Team Image */
.contact-form-container .contact-location .contact-image {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.contact-form-container .contact-location .contact-image img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 5%;
  margin-right: 10%;
}

.geolocation-icon {
  font-size: 40px;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-right: 80px;
}

.geolocation-icon a {
  color: #0674eb;
  text-decoration: none;
}

.geolocation-icon a:hover {
  color: #1182fb;
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;
}

/* Success Message */
.notification.success {
  background-color: #4CAF50;
  color: white;
}

/* Error Message */
.notification.error {
  background-color: #ff3333;
  color: white;
}

/* Fade In and Out Animation */
@keyframes fadeInOut {
  0% { opacity: 0; transform: translateY(-20px); }
  10% { opacity: 1; transform: translateY(0); }
  90% { opacity: 1; }
  100% { opacity: 0; transform: translateY(-20px); }
}

@media (max-width: 768px) {
  .contact-form-container{
    margin-bottom: 50px;
  }

  .contact-form-container .contact-location .contact-image img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 5%;
    margin-left: 10%;
  }

}
/* Responsive Design */
@media (max-width: 1024px) {
  .contact-form-container {
    flex-direction: column;
    padding: 30px;
    gap: 30px;
  }

  .contact-form-container .contact-form-content,
  .contact-form-container .contact-location {
    max-width: 100%;
  }

  .contact-form-container .contact-form-content .contact-form button {
    width: 100%;
  }

  .contact-form-container .contact-location .contact-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5%;
    margin-left: 10%;
  }

}
