/* Blog Section */
.blog-post-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: 50px auto;
    padding: 0 300px ;
    font-family: Arial, sans-serif;
  }
  
  /* Blog Content */
  .blog-post-top .blog-content {
    text-align: left;
    font-size: 20px;
    line-height: 1.6;
    color: #333;
  }
  
  .blog-post-top  .blog-content h2 {
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  
  .blog-post-top  .blog-content p {
    margin-bottom: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 50;
  }
  
  /* Highlighted Text */
  .blog-post-top  .blog-content .highlight {
    color: #2172c1;
    font-weight: bold;
  }
  
  /* Image Container */
  .blog-post-top .blog-image-container {
    width: 100%;
    display: block;
  }
  
  .blog-post-top .blog-image-container img {
    width: 100%;
    height: 500px;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .blog-post-top hr {
    border: none;
    height: 2px;
    background: linear-gradient(to right, #2172c1, #33a1fd);
    margin: 40px 0;
    width: 40%;
  }
  /* Responsive Design */
  @media (max-width: 768px) {
    .blog-post-top {
      padding: 0 15px;
    }
  
    .blog-post-top .blog-content {
      font-size: 16px;
    }
  
    .blog-post-top .blog-image-container img {
      width: 100%;
      max-width: 600px;
    }
  }
  