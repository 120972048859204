/* Job Offers Section */
.job-offers {
    width: 100%;
    margin: 0 0 300px 0;
    text-align: center;
  }
  
  /* Header Section */
  .job-offers .job-header {
    background-color: #666; /* Light blue background */
    padding: 100px 0;
  }
  
  .job-offers .job-header h2 {
    font-size: 32px;
    font-weight: bold;
    color: white;
    margin: 40px 0;
    white-space: nowrap;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  /* Search Bar */
  .search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .search-bar input {
    width: 50%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .search-button {
    background-color: #534d4c;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: 0.3s ease-in-out;
  }
  
  .search-button i {
    margin-right: 8px;
  }
  
  .search-button:hover {
    background-color: #3e3a39;
  }
  
  /* Job Card */
  .job-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .job-card {
    background: white;
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    max-width: 450px;
    text-align: left;
  }
  
  .job-card h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .job-info p {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
  }
  
  .job-info i {
    color: #2172c1;
  }
  
  .view-more {
    background-color: #534d4c;
    color: white;
    padding: 10px 25px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
  }
  .view-more a {
    list-style: none;
    color: inherit;

  }
  .view-more i {
    margin-right: 8px;
  }
  
  .view-more:hover {
    background-color: #3e3a39;
  }
  
  /* Spontaneous Application */
  .spontaneous-application {
    text-align: center;
    margin-top: 40px;
  }
  
  .spontaneous-application h3 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .jobpass-button {
    background-color: #060066;
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 30px;
    cursor: pointer;
  }
  
  .jobpass-button i {
    margin-right: 8px;
  }
  
  .jobpass-button span {
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .jobpass-button:hover {
    background-color: #04004a;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .search-bar {
      flex-direction: column;
      width: 80%;
      margin: auto;
    }
  
    .search-bar input {
      width: 100%;
    }
  
    .job-container {
      flex-direction: column;
      align-items: center;
    }
  
    .job-card {
      width: 90%;
    }
  }
  