/* Testimonials Section */
.testimonials-section {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

/* Title Styling */
.testimonials-section .testimonials-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: left;
  color: #626262;
}

/* Message Styling */
.testimonials-section .testimonial-message {
  font-size: 1.2rem;
  font-style: italic;
  line-height: 1.6;
  color: #666;
  margin: 0 auto 1.5rem auto;
  max-width: 700px;
}

/* Center the Slick slider */
.testimonials-section .slick-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* Ensure slides are properly aligned */
.testimonials-section .slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  outline: none; /* Remove the focus outline */
}

/* Testimonial Card */
.testimonials-section .testimonial-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  padding: 2rem;
  border-radius: 12px;
  background-color: transparent; /* No card background */
}

/* Avatar Styling */
.testimonials-section .testimonial-avatar img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

/* Name Styling */
.testimonials-section .testimonial-name {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
  color: #333;
}

/* Position Styling */
.testimonials-section .testimonial-position {
  font-size: 1rem;
  color: #007bff; /* Blue color */
  text-decoration: none;
}

/* Slick Dots Styling */
.testimonials-section .slick-dots {
  position: absolute;
  bottom: -25px;
}

.testimonials-section .slick-dots li button:before {
  font-size: 10px;
  color: #bbb;
  opacity: 1;
}

.testimonials-section .slick-dots li.slick-active button:before {
  color: #007bff;
  opacity: 1;
}

/* Navigation Arrows */
.testimonials-section .slick-prev,
.testimonials-section .slick-next {
  font-size: 50px;
  z-index: 2;
  color: #007bff;
  cursor: pointer;
}

.testimonials-section .slick-prev:hover,
.testimonials-section .slick-next:hover {
  font-size: 34px;
  z-index: 2;
  color: #007bff;
}

.testimonials-section .slick-prev {
  left: -50px;
}

.testimonials-section .slick-next {
  right: -50px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .testimonial-card {
    width: 90%;
  }

  .slick-prev {
    left: -20px;
  }

  .slick-next {
    right: -20px;
  }
}
