.news {
  padding: 0 50px;
  margin: auto;
  width: 70%;
  max-width: 1100px;
}

.news h2 {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: left;
}

.news .news-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);  
  max-width: 1300px;
  width: 100%;
  gap: 40px;
 
}

.news-grid .news-card {
  background-color: #2172c1;
  width: 100%;
  max-width: 400px;
  min-height: 500px;
  background: #808080;
  border-radius: 0px;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.news-grid .news-card:hover {
  transform: translateY(-5px);
}

.news-grid .news-card .news-image {
  height: 60%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.news-grid .news-card .news-image .news-category {
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: bold;
  background-color: #2172c1;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 10px;
  text-transform: uppercase;
}

.news-grid .news-card .news-content {
  background-color: #2172c1;
  height: 33%;
  color: #fff;
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news-grid .news-card .news-content h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;

}

.news-grid .news-card .news-content a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}


/* Responsive Styles */
@media (max-width: 1024px) {
  .news {
    width: 90%;
    padding: 0 20px;
  }

  .news-grid .news-card {
    width: 45%;
    height: auto;
 
  }
}

/* Fix for widths between 768px and 1024px */
@media (min-width: 769px) and (max-width: 1024px) {
  .news .news-grid {
    grid-template-columns: repeat(2, 1fr); /* Display 2 cards per row */
    gap: 20px; /* Adjust spacing between cards */
    justify-items: center; /* Center the grid items */
  }

  .news .news-grid .news-card {
    width: 90%; /* Adjust width for better fit */
    max-width: 400px; /* Limit the maximum width */
    height: auto; /* Allow height to adjust dynamically */
  }
}

@media (max-width: 768px) {
  .news {
    width: 100%;
    padding: 0 10px;
  }

  .news h2 {
    font-size: 24px;
    text-align: center;
  }

  .news .news-grid {
    display: grid;
    grid-template-columns: 1fr; /* Ensures only 1 column */
    gap: 20px;
    margin-left: 0; /* Remove margin-left to prevent shifting */
    justify-items: center; /* Centers the grid items */
 
  }

  .news .news-grid .news-card {
    max-width: 90%; /* Adjust width for better fit */
    width: 100%; /* Ensures it takes full width */
    height: auto;
  }

  .news .news-content h3 {
    font-size: 18px;
  }

  .news .news-content a {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .news {
    padding: 0 5px;
  }

  .news h2 {
    font-size: 20px;
  }

  .news-content h3 {
    font-size: 16px;
  }

  .news-content a {
    font-size: 14px;
  }

  .news-category {
    font-size: 10px;
    padding: 3px 6px;
  }
}