/* Similar Articles Section */
.similar-articles {
    width: 100%;
    max-width: 1500px;
    margin: 50px auto;
    font-family: Arial, sans-serif;
  }
  
  .similar-articles h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Articles Container */
  .similar-articles .articles-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: flex-start;
  }
  
  /* Individual Article Card */
  .similar-articles .articles-container .article-card {
    width: 150px; /* Adjust for image proportions */
    text-align: center;
    cursor: pointer;
  }
  
  .similar-articles .articles-container .article-card img {
    width: 100%;
    height: 120px; /* Fixed height for consistency */
    object-fit: cover;
    border-radius: 5px;
  }
  
  .similar-articles .articles-container .article-card p {
    font-size: 14px;
    font-weight: bold;
    color: #0070c9; /* Blue text */
    margin-top: 5px;
    text-decoration: none;
    text-align: left;
    line-height: 1.3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .similar-articles .articles-container {
      justify-content: center;
    }
  
    .article-card {
      width: 100px;
    }
  
    .article-card img {
      height: 90px;
    }
  }
  