/* Container */
.image-list-container {
  text-align: center;
  background-color: #f9f9f9;
  width: 100%;
  max-width: 100%; /* Prevent it from exceeding the available width */
  min-width: 100%; /* Prevent it from being smaller than the available width */
  margin: 0 auto; /* Center the container */
 
  box-sizing: border-box; /* Include padding and border in width calculation */
  overflow: hidden;
}

/* Section Title */
.section-title { 
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
}

/* Image Grid Layout */
.image-grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(150px, 1fr));
 
}

/* Image Item */
.image-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  max-height: 340px;
}

/* Scale effect on hover */
.image-item:hover {
  transform: scale(1.05);
}

/* Image Styling */
.image-item .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* Overlay (Text Background) */
.image-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

/* Animated Random Appearance */
@keyframes randomAppear {
  0%, 100% { opacity: 0; }
  20% { opacity: 1; }
  40% { opacity: 0; }
  60% { opacity: 1; }
  80% { opacity: 0; }
}

/* Apply animation with random timing for each image */
.image-item:nth-child(1) .overlay { animation: randomAppear 10s infinite; }
.image-item:nth-child(2) .overlay { animation: randomAppear 16s infinite; }
.image-item:nth-child(3) .overlay { animation: randomAppear 14s infinite; }
.image-item:nth-child(4) .overlay { animation: randomAppear 17s infinite; }
.image-item:nth-child(5) .overlay { animation: randomAppear 15.5s infinite; }
.image-item:nth-child(6) .overlay { animation: randomAppear 16.5s infinite; }
.image-item:nth-child(7) .overlay { animation: randomAppear 15.8s infinite; }
.image-item:nth-child(8) .overlay { animation: randomAppear 17.2s infinite; }
.image-item:nth-child(9) .overlay { animation: randomAppear 16.3s infinite; }
.image-item:nth-child(10) .overlay { animation: randomAppear 15.7s infinite; }

.image-item:nth-child(11) .overlay { animation: randomAppear 15.8s infinite; }
.image-item:nth-child(12) .overlay { animation: randomAppear 17.2s infinite; }
.image-item:nth-child(13) .overlay { animation: randomAppear 16.3s infinite; }
.image-item:nth-child(14) .overlay { animation: randomAppear 15.7s infinite; }
.image-item:nth-child(15) .overlay { animation: randomAppear 15.7s infinite; }


/* Overlay Text */
.image-item .overlay .overlay-text {
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  opacity: 1;
}
/* Responsive Styles for Smartphones */
@media (max-width: 1024px) {
  .image-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens */
  }
}

@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller screens */
    margin: 0 5px; /* Add some margin */
  }
}
/* Responsive Styles for Smartphones */
@media (max-width: 600px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on small screens */
    gap: 10px; /* Add some space between images */
    width: 100%; /* Ensure grid fits within the container */
  }

  .image-item {
    max-height: 200px; /* Reduce max height for smaller screens */
  }

  .section-title {
    font-size: 24px; /* Slightly smaller title font size */
  }

  .image-item .overlay .overlay-text {
    font-size: 14px; /* Smaller overlay text */
  }
}
