.hero-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: 75%; /* Restrict height to 60% of viewport */
  
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  /* aspect-ratio: 20 / 15;  */
}

/* The video should scale proportionally */
.video-background {
  max-width: 2824px; /* Set max width */
  width: 100%;
  height: auto;
 
  z-index: 1;
  position: relative; 
  object-fit: cover; /* Cover the entire container */
}

/* @media (min-width: 1524px) {
  .hero-container {
    aspect-ratio: 20 / 10; 
  }
  .video-background {
    object-fit: contain;
  }
} */
/* Overlay to darken video */
/* .overlay {
  position: absolute; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);  
  z-index: 1; 
} */

/* Content */
/* .hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
  top: 50%;
  transform: translateY(-50%);
} */

/* .title {
  font-size: 50px;
  font-weight: bold;
  text-transform: uppercase;
} */

/* Responsive Styles */
@media (max-width: 768px) {
 

  .hero-container .title {
    font-size: 40px; /* Adjust font size for tablets */
  }
}

@media (max-width: 480px) {
 
}