/* Page Container */
.certification-container {
    width: 90%;
    max-width: 1200px;
    margin: 50px auto;
    font-family: "Arial", sans-serif;
    color: #333;
  }
  
  /* Page Title */
  .page-title {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  /* Certification Section */
  .certification-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    padding: 30px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Reverse layout for second section */
  .reverse {
    flex-direction: row-reverse;
  }
  
  /* Text Content */
  .certification-content {
    flex: 1;
    padding: 20px;
  }
  
  .certification-content h2 {
    font-size: 28px;
    color: #2172c1;
    margin-bottom: 10px;
  }
  
  .certification-content h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  
  .certification-content p {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  .certification-content ul {
    list-style: none;
    padding: 0;
  }
  
  .certification-content ul li {
    font-size: 20px;
    background: url("https://cdn-icons-png.flaticon.com/128/845/845646.png") no-repeat left center;
    background-size: 18px;
    padding-left: 30px;
    margin-bottom: 10px;
  }
  
  /* Image Styling */
  .certification-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .certification-image img {
    max-width: 80%;
    height: auto;
    border-radius: 10px;
  }
  

  .certification-container .cta-button {
    background-color: #1C74B6;
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 15px 4px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  .certification-container .cta-button .cta-link {
    color: inherit; /* Inherit color from the button */
    text-decoration: none !important; 
    text-decoration-style: none !important; 
    display: block; /* Ensure the link takes up the full button space */
    width: 100%;
   
  }
  .certification-container .cta-button:hover {
    background-color: #1C74B6;
  }
  /* Responsive Design */
  @media (max-width: 768px) {
    .certification-section {
      flex-direction: column;
      text-align: center;
      padding: 20px;
    }
  
    .reverse {
      flex-direction: column;
    }
  
    .certification-content {
      padding: 15px;
    }
  
    .certification-image img {
      max-width: 90%;
    }
  }
  