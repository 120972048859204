.company-overview {
  text-align: center;
  padding: 40px 0 50px 0;
  background-color: #fff;
  /* Light background for contrast */
  font-family: 'Arial', sans-serif;
  /* Consistent font family */
  width: 60%;
  /* Set the width to 60% */
  margin: 0 auto;
  /* Center the block */
}

.company-overview .section-title {
  font-size: 20px;
  color: #1C74B6;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-align: left;
  
}

.company-overview .main-title {
  font-size: 36px;
  font-weight: bold;
  margin: 20px 0;
  color: #1C74B6;
  text-align: left;
  letter-spacing: 3.5px;
}

.main-title .highlight {
  color: #1C74B6;
 
}

.company-overview .subtitle {
  font-size: 20px;
  margin-bottom: 50px;
  color: #555;
  line-height: 1.6;
  text-align: left;
}

.subtitle .blue-text {
  color: #1C74B6;
  font-weight: bold;
}

.subtitle .bold-text {
  font-weight: bold;
  color: #333;
}


/* Stats Section */
.company-overview .stats-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 50px;
}

.company-overview .stats-container .stat-box {
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: white;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
  font-weight: bold;
  margin-right: 0;
}
 
.company-overview .stats-container .stat-box .stat-icon {
  
  width: 50%;
  height: 50%;
  margin-bottom: 10px;


}
.company-overview .stats-container .stat-box span {
  font-size: 24px;

}
.company-overview .stats-container .stat-1 {
 
  color: #000000;
}

.company-overview .stats-container .stat-2 {
 
  color: #000000;
}

.company-overview .stats-container .stat-3 {
 
  color: #000000;
}

.company-overview .stats-container .stat-4 {
 
  color: #000000;
}


 


.stat-box:hover {
  transform: scale(1.1);
}

/* Company Description */
.company-overview .company-description {
  font-size: 20px;
  margin-bottom: 40px;
  color: #555;
  line-height: 1.8;
  text-align: left;
}

.company-description .blue-text {
  color: #1C74B6;
  font-weight: bold;
}

.company-description .bold-text {
  font-weight: bold;
  color: #333;
}

.company-overview .cta-button {
  background-color: #1C74B6;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 40px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}
.cta-button .cta-link {
  color: inherit; /* Inherit color from the button */
  text-decoration: none !important; 
  text-decoration-style: none !important; 
  display: block; /* Ensure the link takes up the full button space */
  width: 100%;
 
}
.company-overview .cta-button:hover {
  background-color: #1C74B6;
}

/* Activity Section */
.company-overview .activity-title {
 
  font-weight: bold;
  margin-top: 60px;
  color: #1C74B6;
  text-align: left;
}

.company-overview .activity-description {
  font-size: 20px;
  margin-top: 20px;
  color: #555;
  line-height: 1.8;
  text-align: left;
}

.activity-description .blue-text {
  color: #1C74B6;
  font-weight: bold;
}

@media (max-width: 768px) {
  .company-overview {
    width: 80%;
    padding: 20px 0;
  }

  .company-overview .section-title,
  .company-overview .main-title,
  .company-overview .subtitle,
  .company-overview .company-description,
  .company-overview .activity-title,
  .company-overview .activity-description {
    text-align: center;
  }

  .company-overview .stats-container {
    flex-direction: column;
    gap: 20px;
  }

  .company-overview .stats-container .stat-box {
    width: 100%;
    height: auto;
    margin-right: 0;
  }

  .company-overview .cta-button {
    width: 100%;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .company-overview {
    width: 90%;
    padding: 10px 0;
  }

  .company-overview .section-title {
    font-size: 18px;
  }

  .company-overview .main-title {
    font-size: 28px;
  }

  .company-overview .stats-container {
    flex-direction: column;
    gap: 10px;
    align-items: center; 
    justify-content: center;  
  }

  .company-overview .stats-container .stat-box {
    width: 100%;
    height: auto;
 
  }

  .company-overview .subtitle,
  .company-overview .company-description,
  .company-overview .activity-description {
    font-size: 16px;
  }

  .company-overview .cta-button {
    font-size: 18px;
    padding: 8px 16px;
  }
}

 
/* Enhanced Responsiveness for stats-container */
@media (max-width: 1324px) and (min-width: 769px) {
  .company-overview .stats-container {
    display: grid; /* Use grid layout */
    grid-template-columns: repeat(4, 1fr); /* Ensure 4 items in a single row */
    gap: 20px; /* Add spacing between items */
    justify-content: center; /* Center the grid */
  }

  .company-overview .stats-container .stat-box {
    width: 100%;  
    height: auto;  
  }
}

